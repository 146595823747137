export default function FooterComponent() {
  return (
    <footer>
      <div class="container">
        <p>&copy; Revolution 2022 - Tous droits réservés.</p>
        {/* <ul class="list-inline">
          <li class="list-inline-item">
            <a href="/assets/privacy-policy-fr.html" target="_blank">
              Politique de confidentialité
            </a>
          </li>
          <li class="list-inline-item">
            <a href="/assets/terms-of-service-fr.html" target="_blank">
              Conditions générales d'utilisation
            </a>
          </li>
          <li class="list-inline-item">
            <a href="/assets/terms-of-service-fr.html" target="_blank">
              Conditions générales de vente
            </a>
          </li>
        </ul> */}
      </div>
    </footer>
  );
}
