import { useState, useCallback } from "react";
import moment from "moment";
import useToken from "./tokenService";
import useGyms from "./gymService";
import axios from "axios";

export default function useCommercialsActivities() {
  const { token } = useToken();
  const { gym } = useGyms();
  const [gymInfos, setGymsInfos] = useState({ gymInfos: [] });
  const [prospectingInfos, setProspectingInfos] = useState([]);
  const [totalCom, setTotalCom] = useState(0);
  const [totalProspect, setTotalProspect] = useState(0);
  const [totalAthleteSent, setTotalAthleteSent] = useState(0);

  const [currentMonth, setMonth] = useState(moment());
  const fetchGymInfos = useCallback(async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/gyms/infos/${gym.value}?start_date=${moment(currentMonth).startOf(
        "Month"
      )}&end_date=${moment(currentMonth).endOf("Month")}`,
      {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        data: {
          start_date: currentMonth,
          end_date: currentMonth,
        },
      }
    );
    let totalCom = 0;
    let totalProspect = 0;
    let totalAthleteSent = 0;
    if (result.status === 200) {
      result.data.map((commercialInfo) => {
        totalCom += commercialInfo.totalCom;
        totalProspect += commercialInfo.number_of_athlete;
        totalAthleteSent += commercialInfo.totalAthleteSent;
      });

      setGymsInfos(result.data);
    }

    setTotalCom(totalCom);
    setTotalProspect(totalProspect);
    setTotalAthleteSent(totalAthleteSent);
  }, [setGymsInfos, setTotalCom, setTotalProspect, currentMonth, gym.value, token]);

  const fetchGymProspecting = useCallback(async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/gyms/prospecting?start_date=${moment(currentMonth).startOf(
        "Month"
      )}&end_date=${moment(currentMonth).endOf("Month")}`,
      {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        data: {
          start_date: currentMonth,
          end_date: currentMonth,
        },
      }
    );

    setProspectingInfos(result.data.result);
  }, [setProspectingInfos, currentMonth, token]);

  return {
    fetchGymInfos,
    gymInfos,
    setMonth,
    currentMonth,
    totalCom,
    totalProspect,
    totalAthleteSent,
    fetchGymProspecting,
    prospectingInfos,
  };
}
