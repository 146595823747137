import { request } from "../services/axiosService";

// -- Auth

export const registerAthlete = async (body) => {
  const route = "/auth/athlete/website/sign-up";

  return request("api", "post", false, route, null, body);
};

export const checkEmail = async (email) => {
  const route = `/auth/userExist/${email}`;

  return request("api", "get", false, route, null);
};

export const checkPhone = async (phone) => {
  const route = `/auth/phone/${phone}`;

  return request("api", "get", false, route, null);
};

export const getWebSiteData = async () => {
  return {
    url: "https://www.youtube.com/watch?v=2HiDtov2pJo&list=PL3L9pkm9gco9vV1qusgw8R8WlYeqMpqBP&ab_channel=%5BR%5DEVOLUTIONPersonalTrainer",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque cursus mollis tempus. Sed efficitur, quam non pulvinar congue, diam sem sollicitudin nibh, sit amet aliquam metus sapien nec ipsum. Suspendisse ut magna finibus, consequat nisl id, laoreet risus. Donec porta bibendum facilisis. Fusce ac molestie ante. Nunc imperdiet ullamcorper orci, sed feugiat lorem varius rhoncus. Morbi finibus nisi sed tincidunt mattis. Phasellus sed mi enim.Pellentesque non varius risus. Donec eleifend pretium augue, et eleifend lacus mollis ac. Ut dapibus augue sed eros lobortis condimentum. Nullam auctor, urna nec tincidunt cursus, nulla massa fermentum ex, vel aliquet risus sapien ut quam. Duis commodo commodo ipsum in viverra. In tincidunt dui a nunc eleifend, non sodales tortor sodales. Nam eu ipsum eget libero ullamcorper tempus in sit amet risus. In hac habitasse platea dictumst. Quisque mattis leo in aliquet tincidunt. Vivamus at suscipit turpis. Quisque non dui feugiat lacus ultricies gravida.",
  };
};

export const getSpecialities = async () => {
  return request("api", "get", false, "/specialties");
};

export const getGyms = async () => {
  return request("api", "get", false, "/gyms");
};

export const getCoachs = async (gymId) => {
  return request("api", "get", false, `/coach/by_gym_place/${gymId}`);
};

export const checkForgotPasswordToken = async (token) => {
  return request("api", "post", false, "/auth/check-reset-password-token", null, {
    token,
  });
};

export const sendNewPassword = async (password, confirm, reset_code, isCoach) => {
  return request("api", "post", false, isCoach ? "/auth/reset-password-coach" : "/auth/reset-password-athlete", null, {
    password,
    confirm,
    reset_code,
  });
};
