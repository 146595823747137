import { useEffect } from "react";
import { FormFeedback } from "reactstrap";
import "./Goals.css";
export default function Goals({ defaultGoals, goals, setGoals, addDefaultGoals, setGoalInput, goalInput, error }) {
  useEffect(() => {});
  return (
    <div className="mb-3">
      <span className="text-white label"> Objectif(s) :</span>
      <div className="row justify-content-center  mt-2">
        {defaultGoals.length &&
          defaultGoals.map((goal) => {
            const isSelected = goals.includes(goal.value);
            return (
              <div
                key={goal.value}
                className={`col-md-4 col-lg-4 col-4 justify-content-center align-items-center goal ${
                  isSelected ? "goal-bg" : "goal-border"
                }`}
                onClick={() => setGoals(goal)}
              >
                <p
                  className="m-0 small mb-0 goal-text text-center label"
                  style={{ color: isSelected ? "#000" : "#fff" }}
                >
                  {goal.value}
                </p>
              </div>
            );
          })}
      </div>
      {error && <span style={{ color: "#dc3545", fontSize: "0.875em", marginTop: "0.25rem" }}>{error}</span>}
    </div>
  );
}

Goals.propTypes = {};
