import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import "./TrainingDays.css";
const days = [
  { value: "L", key: "is_monday_preferred" },
  { value: "M", key: "is_tuesday_preferred" },
  { value: "ME", key: "is_wednesday_preferred" },
  { value: "J", key: "is_thursday_preferred" },
  { value: "V", key: "is_friday_preferred" },
  { value: "S", key: "is_saturday_preferred" },
  { value: "D", key: "is_sunday_preferred" },
];

export default function TrainingDays({ days_preference, setDayPreference, time_preference, setTimePreference, error }) {
  return (
    <div>
      <span className="text-white label">Jour(s) d’entrainement préféré(s) :</span>
      <div className="row justify-content-center  mt-2">
        {days.map((day, index) => {
          const isSelected = days_preference[day.key] === true;
          return (
            <div
              key={day.key}
              className=" day justify-content-center align-items-center"
              style={{
                backgroundColor: isSelected ? "#2CDEE4" : "#1E2026",
                marginLeft: index === 0 ? 0 : 5,
                marginRight: index === days.length - 1 ? 0 : 5,
                border: isSelected ? "" : "0.2px solid #fff",
              }}
              onClick={() => setDayPreference(day.key)}
            >
              <p className=" m-0 small mb-0 day-text text-center" style={{ color: isSelected ? "#000" : "#fff" }}>
                {day.value}
              </p>
            </div>
          );
        })}
      </div>
      {error && <span style={{ color: "#dc3545", fontSize: "0.875em", marginTop: "0.25rem" }}>{error}</span>}

      <div className="mt-4">
        <span className="text-white label mt-3">Créneau horaire préféré :</span>
        <p className="text-white text-center label mt-1 mb-2">
          Entre <span className="text-colored">{time_preference.start_time}H </span>et
          <span className="text-colored"> {time_preference.end_time}H</span>
        </p>
        <Range
          allowCross={false}
          trackStyle={[
            { backgroundColor: "#2CDEE4", height: 8 },
            { backgroundColor: "#2CDEE4", height: 8 },
          ]}
          handleStyle={[
            { backgroundColor: "#2CDEE4", borderColor: "#2CDEE4", height: 16, width: 16 },
            { backgroundColor: "#2CDEE4", borderColor: "#2CDEE4", height: 16, width: 16 },
          ]}
          railStyle={{ backgroundColor: "#282C3A", height: 8 }}
          min={0}
          max={24}
          defaultValue={[time_preference.start_time, time_preference.end_time]}
          tipFormatter={(value) => `${value}%`}
          draggableTrack
          onChange={setTimePreference}
        />
      </div>
    </div>
  );
}

TrainingDays.propTypes = {};
