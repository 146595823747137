import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu, FormGroup, Label, Input } from "reactstrap";
import useGyms from "../../services/gymService";
import axios from "axios";
export default function LoginScreen({ setToken }) {
  const [isOpen, setOpen] = useState(false);

  const { fetchGyms, gyms, setSelectedGym, selectedGym } = useGyms();
  useEffect(() => {
    fetchGyms();
  }, [fetchGyms]);

  const [password, setPassword] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedGym || !password || password.trim() === "") return;
    const req = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/gyms/authenticate`,
      data: {
        gymId: selectedGym.value,
        password,
      },
    });
    if (req.status === 200) {
      setToken({ token: req.data, selectedGym });
    }
  };

  const setCheck = () => setOpen(!isOpen);

  return (
    <section class="login" id="login">
      <div className="justify-content-center mx-5">
        <img src="../logo2.png" className="brand_logo img-fluid" alt="Responsive" width="600px" />
        <div className="pt-5 justify-content-center">
          <div className="col-md-8 offset-md-2 col-sm-8 offset-sm-2">
            <p className="title text-center pb-3">INTERFACE SALLE</p>
            <form onSubmit={handleSubmit}>
              <p className="text-white label"> Identifiant :</p>
              <Dropdown isOpen={isOpen} toggle={setCheck} className="bg-white rounded w-100">
                <DropdownToggle caret className="bg-white text-black rounded w-100">
                  {(selectedGym && selectedGym.label) || "Recherche le nom de ta salle"}
                </DropdownToggle>
                <DropdownMenu style={{ width: "100%", backgroundColor: "#1E2026" }}>
                  {gyms.length &&
                    gyms.map((gym) => (
                      <div key={gym.value}>
                        <DropdownItem className="text-white bg-transparent" onClick={() => setSelectedGym(gym)}>
                          {gym.label}
                        </DropdownItem>
                        <DropdownItem divider />
                      </div>
                    ))}
                </DropdownMenu>
              </Dropdown>
              <FormGroup className="mt-3">
                <Label className="text-white label">Mot de passe :</Label>
                <Input
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  className="w-100"
                  name="password"
                  placeholder="Mot de passe"
                  type="password"
                />
              </FormGroup>
              <div className="text-center">
                <button type="submit" className="loginButton mt-3">
                  Se connecter
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

LoginScreen.propTypes = {
  setToken: PropTypes.func.isRequired,
};
