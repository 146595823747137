import { useState, useCallback } from "react";
import axios from "axios";
import useToken from "./tokenService";
import useGyms from "./gymService";

const defaultDaysPreferences = {
  is_monday_preferred: false,
  is_tuesday_preferred: false,
  is_wednesday_preferred: false,
  is_thursday_preferred: false,
  is_friday_preferred: false,
  is_saturday_preferred: false,
  is_sunday_preferred: false,
};
const defaultTimePreferences = {
  start_time: 6,
  end_time: 23,
};

export default function useProspects() {
  const { token } = useToken();
  const { gym } = useGyms();

  //fetch val
  // const [prospect, setProspect] = useState(defaultProspect);
  const [commercials, setCommercials] = useState({ commercials: [] });
  const [defaultGoals, setDefaultGoals] = useState([]);

  const [reloadData, setReloadData] = useState(false);

  //form
  const [selectedCommercial, setCommercialInput] = useState();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("male");
  const [age, setAge] = useState();
  const [commercialId, setCommercialId] = useState(0);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [goalInput, setGoalInput] = useState("");
  const [goals, setGoals] = useState([]);

  const [daysPreferences, setDaysPreferences] = useState(defaultDaysPreferences);
  const [timesPreferences, setTimePreferences] = useState(defaultTimePreferences);
  const [healthProbleme, setHealthProblemes] = useState("");
  //

  const [validate, setValidate] = useState({});

  const fetchGoals = useCallback(async () => {
    const result = await axios.get(`${process.env.REACT_APP_API_URL}/specialties`);
    setDefaultGoals(result.data);
  }, []);

  const addDefaultGoals = () => {
    if (goalInput.trim() === "") return;
    const goalToAdd = { value: goalInput };
    setDefaultGoals([...defaultGoals, goalToAdd]);
    setGoal(goalToAdd);
    setGoalInput("");
  };

  const setGoal = useCallback((val, index) => {
    const formatGoals = goals;
    if (formatGoals.includes(val.value)) {
      const index = formatGoals.indexOf(val.value);
      if (index > -1) {
        formatGoals.splice(index, 1);
      }
    } else formatGoals.push(val.value);
    setGoals([...formatGoals]);
  }, [goals]);

  const fetchCommercials = useCallback(async () => {
    const result = await axios.get(`${process.env.REACT_APP_API_URL}/commercials/by_gym_place/${gym.value}`);
    const data = result.data.map((commercial) => {
      return {
        value: commercial.id,
        label: `${commercial.first_name} ${commercial.last_name}`,
      };
    });
    setCommercials(data);
  }, [setCommercials, gym.value]);

  const onSetCommercialInput = useCallback((val) => {
    setCommercialInput(val);
    setCommercialId(val.value);
  }, []);

  const setDayPreferencesInput = useCallback((key) => {
    const preferences = daysPreferences;
    preferences[key] = !preferences[key];
    setDaysPreferences({ ...preferences });
  }, [daysPreferences]);

  const setTimePreferencesInput = useCallback((times) => {
    setTimePreferences({
      start_time: times[0],
      end_time: times[1],
    });
  }, []);

  const sendProspect = async () => {
    const body = {
      commercial_id: commercialId,
      preferred_gym_id: gym.value,
      last_name: lastname,
      first_name: firstname,
      phone,
      email,
      goals,
      days_preference: daysPreferences,
      time_preference: timesPreferences,
      health_issues: false,
      health_problem_description: healthProbleme,
      age,
      gender
    };
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/commercials/prospect`, body, {
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
    });
    if (res.status === 200) {
      setFirstname("");
      setLastname("");
      setPhone("");
      setEmail("");
      setGoals([]);
      fetchGoals();
      setDaysPreferences({ ...defaultDaysPreferences });
      setTimePreferences(defaultTimePreferences);
      setHealthProblemes("");
      setReloadData(true);
    }
  };

  const onValidate = async () => {
    setValidate({});
    let newValidate = {};
    if (firstname === "") {
      newValidate.firstName = "Requis";
    } else if (firstname.length < 2) {
      newValidate.firstName = "2 caractères minimum";
    }
    if (lastname === "") {
      newValidate.lastName = "Requis";
    } else if (lastname.length < 2) {
      newValidate.lastName = "2 caractères minimum";
    }
    if (email === "") {
      newValidate.email = "Requis";
    }
    if (phone === "") {
      newValidate.phone = "Requis";
    }
    if (commercialId === 0) {
      newValidate.commercial = "Requis";
    }
    if (goals.length === 0) {
      newValidate.goals = "Il faut au moins un objectif";
    }
    if (!age) {
      newValidate.age = "Requis";
    }
    if (age > 100) {
      newValidate.age = "Tu dois entrer un âge correct";
    }
    if (age < 15) {
      newValidate.age = "Tu dois entrer un âge correct";
    }
    setValidate(newValidate);

    if (!Object.keys(newValidate).length) return true;
  };

  return {
    fetchCommercials,
    commercials,
    fetchGoals,
    defaultGoals,
    onSetCommercialInput,
    setFirstname,
    firstname,
    setLastname,
    lastname,
    setPhone,
    phone,
    setEmail,
    email,
    addDefaultGoals,
    setGoalInput,
    goals,
    setGoal,
    gender,
    setGender,
    age,
    setAge,
    setDayPreferencesInput,
    daysPreferences,
    setTimePreferencesInput,
    timesPreferences,

    setHealthProblemes,
    healthProbleme,

    selectedCommercial,

    goalInput,

    sendProspect,
    reloadData,
    setReloadData,
    onValidate,
    validate,
  };
}
