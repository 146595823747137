import React from "react";
import "./RegisterPage.css";
import JoinUsComponent from "../../components/JoinUsComponent/JoinUsComponent";

export default function RegisterPage() {
  return (
    <div className="container-fluid">
      <div className="row joinUs mt-3">
        <div className="col-md-12 wrapper mx-auto">
          <img
            src="../logo2.png"
            className="brand_logo img-fluid text-center"
            alt="Responsive"
            width="300px"
            style={{
              margin: "auto",
            }}
          />

          <p className="title mx-4 text-center pb-3 pt-3" style={{ fontSize: "14px" }}>
            Remplis ce formulaire pour nous aider à comprendre tes besoins. Un coach pourra alors te contacter pour te
            proposer un rdv
          </p>
          <JoinUsComponent />
        </div>
      </div>
    </div>
  );
}
