import { useCallback, useEffect, useState } from "react";
import { getSpecialities, getGyms, getCoachs, checkEmail, checkPhone, registerAthlete } from "../../helpers/apiHelper";

const defaultDaysPreferences = {
  is_monday_preferred: false,
  is_tuesday_preferred: false,
  is_wednesday_preferred: false,
  is_thursday_preferred: false,
  is_friday_preferred: false,
  is_saturday_preferred: false,
  is_sunday_preferred: false,
};
const defaultTimePreferences = {
  start_time: 6,
  end_time: 23,
};

const useController = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCoach, setIsOpenCoach] = useState(false);

  const [defaultGoals, setDefaultGoals] = useState([]);
  const [gender, setGender] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [weight, setWeight] = useState("");
  const [size, setSize] = useState("");
  const [sportExperience, setSportExperience] = useState(null);

  const [goals, setGoals] = useState([]);
  const [gyms, setGyms] = useState([]);
  const [coachs, setCoachs] = useState([]);
  const [selectedGym, setSelectedGym] = useState();
  const [selectedCoach, setSelectedCoach] = useState();
  const [gymId, setGymId] = useState();
  const [coachId, setCoachId] = useState();

  const [daysPreferences, setDaysPreferences] = useState(defaultDaysPreferences);
  const [timesPreferences, setTimePreferences] = useState(defaultTimePreferences);
  const [healthProbleme, setHealthProblemes] = useState("");
  const [validate, setValidate] = useState({});
  const [emailError, setEmailError] = useState();
  const [phoneError, setPhoneError] = useState();

  const [isModalOpen, setModalOpen] = useState(false);
  const [isChecking, setIsChecking] = useState(false);

  useEffect(() => {
    const init = async () => {
      const specialities = await getSpecialities();
      setDefaultGoals(specialities.content);
      const gyms = await getGyms();
      const data = gyms.content.map((gym) => {
        return {
          value: gym.id,
          label: gym.name,
        };
      });

      setGyms(data);
      setIsLoading(false);
    };

    init();
  }, []);

  const onSetGymInput = (val) => {
    if (!gymId || gymId !== val.value) {
      setSelectedGym(val);
      setGymId(val.value);
      setSelectedCoach(null);
      setCoachId(null);
    }
  };

  const onSetCoachInput = (val) => {
    if (!coachId || coachId !== val.value) {
      setSelectedCoach(val);
      setCoachId(val.value);
    }
  };

  useEffect(() => {
    const fetchCoach = async () => {
      const res = await getCoachs(gymId);
      const data = [{ label: "Tous les coachs", value: null }];
      res.content.map((coach) => {
        data.push({
          value: coach.id,
          label: `${coach.first_name} ${coach.last_name}`,
        });
      });

      setCoachs(data);
    };

    if (gymId) {
      fetchCoach();
    }
  }, [gymId]);

  const setDayPreferencesInput = useCallback((key) => {
    const preferences = daysPreferences;
    preferences[key] = !preferences[key];
    setDaysPreferences({ ...preferences });
  }, []);

  const setTimePreferencesInput = useCallback((times) => {
    setTimePreferences({
      start_time: times[0],
      end_time: times[1],
    });
  }, []);
  const setGoal = useCallback((val, index) => {
    const formatGoals = goals;
    if (formatGoals.includes(val.value)) {
      const index = formatGoals.indexOf(val.value);
      if (index > -1) {
        formatGoals.splice(index, 1);
      }
    } else formatGoals.push(val.value);
    setGoals([...formatGoals]);
  }, []);

  const onValidate = async () => {
    if (isChecking) return;
    setIsChecking(true);
    setValidate({});
    let newValidate = {};
    if (!gymId) {
      newValidate.gym = "Requis";
    }
    if (gender === "") {
      newValidate.gender = "Requis";
    }
    if (firstname === "") {
      newValidate.firstName = "Requis";
    } else if (firstname.length < 2) {
      newValidate.firstName = "2 caractères minimum";
    }
    if (lastname === "") {
      newValidate.lastName = "Requis";
    } else if (lastname.length < 2) {
      newValidate.lastName = "2 caractères minimum";
    }
    if (email === "") {
      newValidate.email = "Requis";
    }
    if (phone === "") {
      newValidate.phone = "Requis";
    }

    if (age === "") {
      newValidate.age = "Requis";
    } else {
      setAge(Number(age));
      if (age < 15 || age > 100) {
        newValidate.age = "Tu dois entrer un âge correct";
      }
    }

    if (weight === "") {
      newValidate.weight = "Requis";
    } else {
      setWeight(Number(weight));
      if (weight < 30 || weight > 300) {
        newValidate.weight = "Tu dois entrer un poids correct";
      }
    }

    if (size === "") {
      newValidate.size = "Requis";
    } else {
      setSize(Number(size));
      if (size < 100 || size > 300) {
        newValidate.size = "Tu dois entrer une taille correcte";
      }
    }
    if (goals.length === 0) {
      newValidate.goals = "Il faut au moins un objectif";
    }
    if (!Object.values(daysPreferences).includes(true)) {
      newValidate.daysPreferences = "Selectionne au moins un jour d'entrainement";
    }
    setValidate(newValidate);

    if (!Object.keys(newValidate).length) {
      const resEmail = await checkEmail(email);
      if (resEmail.content.exist) {
        setEmailError("Cet email existe déjà");
        setIsChecking(false);

        return;
      }

      setEmailError(null);

      const resPhone = await checkPhone(phone);
      if (resPhone.content.exist) {
        setPhoneError("Ce numéro de téléphone existe déjà");
        setIsChecking(false);

        return;
      }
      setPhoneError(null);

      const body = {
        email: email.toLowerCase(),
        first_name: firstname,
        last_name: lastname,
        gender,
        size,
        age,
        weight,
        phone,
        experience_years: sportExperience || 0,
        goals: goals,
        health_issues: healthProbleme.length ? true : false,
        health_problem_description: healthProbleme,
        password: "Revolution",
        days_preference: daysPreferences,
        time_preference: timesPreferences,
        preferred_gym_id: gymId,
        coach_preference: coachId
          ? {
              type: "specific_coach",
              coach_id: coachId,
            }
          : {
              type: "any_coach",
              coach_id: "0",
            },
      };

      const res = await registerAthlete(body);

      if (res.status === 200) {
        setModalOpen(true);
        setSelectedGym(null);
        setSelectedCoach(null);
        setGymId(null);
        setGender("");
        setEmail("");
        setFirstname("");
        setLastname("");
        setSize("");
        setAge("");
        setWeight("");
        setPhone("");
        setSportExperience(null);
        setGoals([]);
        setHealthProblemes("");
        setDaysPreferences(defaultDaysPreferences);
        setTimePreferences(defaultTimePreferences);
      }
      setIsChecking(false);
    }
    setIsChecking(false);
  };

  const onCloseModal = async () => {
    setModalOpen(false);
  };

  return {
    isLoading,
    isOpen,
    setIsOpen,
    isOpenCoach,
    setIsOpenCoach,
    gyms,
    onSetGymInput,
    onSetCoachInput,
    coachs,
    setGymId,
    goals,
    setGoal,
    defaultGoals,
    firstname,
    setFirstname,
    lastname,
    setLastname,
    phone,
    setPhone,
    email,
    setEmail,
    gender,
    setGender,
    age,
    setAge,
    weight,
    setWeight,
    size,
    setSize,
    healthProbleme,
    setHealthProblemes,
    timesPreferences,
    setTimePreferences,
    daysPreferences,
    setDayPreferencesInput,
    setTimePreferencesInput,
    selectedGym,
    setSelectedGym,
    selectedCoach,
    setSelectedCoach,
    sportExperience,
    setSportExperience,
    validate,
    onValidate,
    emailError,
    phoneError,
    isModalOpen,
    onCloseModal,
    isChecking,
  };
};

export default useController;
