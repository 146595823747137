import axios from "axios";

const serviceBaseUrlMapping = {
  api: process.env.REACT_APP_API_URL,
};

export const request = async (service, method, needAuth, route, query, body, headers) => {
  const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const reqHeaders = {
    ...defaultHeaders,
    ...headers,
  };

  // if (needAuth) {
  //   const auth = await authService.authenticate();
  //   if (auth && auth.authToken) {
  //     reqHeaders.Authorization = `JWT ${auth.authToken}`;
  //   } else {
  //     return null;
  //   }
  // }

  try {
    const res = await axios.request({
      method,
      baseURL: serviceBaseUrlMapping[service],
      url: route,
      headers: reqHeaders,
      params: query,
      data: body,
      validateStatus: (status) => {
        return status < 500;
      },
    });

    if (res.status === 401) {
      // await authService.removeAuth();

      return null;
    }

    return {
      status: res.status,
      content: res.data,
    };
  } catch (err) {
    if (err.response) {
      // await systemService.showError(`${err.response.status} - ${JSON.stringify(err.response.data)}`);

      return {
        status: err.response.status,
      };
    }

    // await systemService.showError(err);

    return {};
  }
};

export default { request };
