import { useState, useCallback } from "react";
import axios from "axios";

export default function useGyms() {
  const [gyms, setGyms] = useState({ gyms: [] });
  const [selectedGym, setSelectedGym] = useState();

  const fetchGyms = useCallback(async () => {
    const result = await axios.get(`${process.env.REACT_APP_API_URL}/gyms`);
    const data = result.data.map((gym) => {
      return {
        value: gym.id,
        label: gym.name,
      };
    });

    setGyms(data);
  }, [setGyms]);

  const saveGym = (gym) => {
    localStorage.setItem("gym", JSON.stringify(gym));
    setGym(gym.value);
  };

  const getGym = () => {
    const gym = localStorage.getItem("gym");
    return JSON.parse(gym);
  };

  const [gym, setGym] = useState(getGym());

  const removeGym = () => {
    localStorage.removeItem("gym");
  };

  return {
    saveGym,
    fetchGyms,
    removeGym,
    setSelectedGym,
    getGym,
    gyms,
    selectedGym,
    gym,
  };
}
