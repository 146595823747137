import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { checkForgotPasswordToken, sendNewPassword } from "../../helpers/apiHelper";
const useController = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [password, setPassword] = useState();
  const [passwordConfirmation, setPasswordConfirmation] = useState();
  const [validate, setValidate] = useState({});
  const [success, setSuccess] = useState();

  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isCoach, setIsCoach] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    const init = async () => {
      const res = await checkForgotPasswordToken(token);

      if (res.status === 200) {
        setIsCoach(res.content.isCoach);
        setIsTokenValid(token);
      }
      setIsLoading(false);
    };

    init();
  }, [token]);

  const onValidate = async () => {
    setValidate({});
    let newValidate = {};
    if (password === "") {
      newValidate.password = "Requis";
    } else if (password.length < 2) {
      newValidate.password = "2 caractères minimum";
    }

    if (passwordConfirmation === "") {
      newValidate.passwordConfirmation = "Requis";
    } else if (passwordConfirmation !== password) {
      newValidate.passwordConfirmation = "Les mots de passes ne sont pas les mêmes";
    }

    setValidate(newValidate);

    if (!Object.keys(newValidate).length) return true;
  };

  const handleSubmit = async () => {
    const validate = await onValidate();

    if (validate) {
      const res = await sendNewPassword(password, passwordConfirmation, token, isCoach);
      if (res.status === 200) {
        setSuccess("Ton mot de passe a été changé");
        setTimeout(() => {
          setSuccess(null);
          setPassword("");
          setPasswordConfirmation("");
        }, 3000);
      }
    }
  };

  return {
    isLoading,
    password,
    passwordConfirmation,
    setPassword,
    setPasswordConfirmation,
    onValidate,
    validate,
    handleSubmit,
    success,
    isTokenValid,
  };
};

export default useController;
