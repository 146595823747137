import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import "./SportsExperiences.css";

export default function SportsExperiences({ sportExperience, setSportExperience }) {
  return (
    <div>
      <div className="mt-3 mb-3">
        <span className="text-white label mt-3">Expériences sportives</span>
        <p className="text-white text-center label mt-1 mb-2">
          <span className="text-colored">
            {sportExperience < 1
              ? "MOINS D'UN AN"
              : sportExperience == 1
              ? `${sportExperience} AN`
              : sportExperience > 9
              ? `PLUS DE ${sportExperience} ANS`
              : `${sportExperience} ANS`}
          </span>
        </p>
        <Range
          allowCross={false}
          trackStyle={[
            { backgroundColor: "#2CDEE4", height: 8 },
            { backgroundColor: "#2CDEE4", height: 8 },
          ]}
          handleStyle={[
            { backgroundColor: "#2CDEE4", borderColor: "#2CDEE4", height: 16, width: 16 },
            { backgroundColor: "#2CDEE4", borderColor: "#2CDEE4", height: 16, width: 16 },
          ]}
          railStyle={{ backgroundColor: "#282C3A", height: 8 }}
          min={0}
          max={10}
          // tipFormatter={(value) => {
          //   console.log(value);
          //   return `${value}%`;
          // }}
          draggableTrack
          onChange={(value) => {
            console.log(value);
            setSportExperience(value[1]);
          }}
        />
      </div>
    </div>
  );
}
