import React from "react";
import useController from "./JoinUs.controller";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  FormGroup,
  Input,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
} from "reactstrap";
import TrainingDays from "../TrainingDays/TrainingDays";
import SportsExperiences from "../SportsExperiencesComponent/SportsExperiences";
import Goals from "../Goals/Goals";
export default function JoinUsComponent() {
  const {
    isOpen,
    setIsOpen,
    isOpenCoach,
    setIsOpenCoach,
    gyms,
    selectedGym,
    onSetGymInput,
    selectedCoach,
    onSetCoachInput,
    coachs,
    goals,
    setGoal,
    defaultGoals,
    firstname,
    setFirstname,
    lastname,
    setLastname,
    phone,
    setPhone,
    email,
    setEmail,
    gender,
    setGender,
    age,
    setAge,
    weight,
    setWeight,
    size,
    setSize,
    healthProbleme,
    setHealthProblemes,
    timesPreferences,
    daysPreferences,
    setDayPreferencesInput,
    setTimePreferencesInput,
    sportExperience,
    setSportExperience,
    validate,
    emailError,
    phoneError,
    onValidate,
    isModalOpen,
    onCloseModal,
    isChecking,
  } = useController();

  const renderGymSelect = () => {
    return (
      <div>
        <div className="mb-2">
          <span className="text-white label">Quelle salle fréquentes-tu ?</span>
        </div>
        <FormGroup>
          <Dropdown isOpen={isOpen} toggle={() => setIsOpen((prev) => !prev)} className="bg-white rounded w-100">
            <DropdownToggle caret className="bg-white text-black rounded w-100 dropdown-menu-right">
              {(selectedGym && selectedGym.label) || "Choisir une salle de sport"}
            </DropdownToggle>
            <DropdownMenu style={{ width: "100%", backgroundColor: "#1E2026" }}>
              {gyms.length &&
                gyms.map((gym) => (
                  <div key={gym.value}>
                    <DropdownItem className="text-white bg-transparent" onClick={() => onSetGymInput(gym)}>
                      {gym.label}
                    </DropdownItem>
                    <DropdownItem divider />
                  </div>
                ))}
            </DropdownMenu>
          </Dropdown>
          {validate.gym && <span className="required">{validate.gym}</span>}
        </FormGroup>
      </div>
    );
  };

  const renderCoachSelect = () => {
    return (
      selectedGym && (
        <div>
          <div className="mb-2">
            <span className="text-white label">Ta demande s’adresse à</span>
          </div>
          <FormGroup>
            <Dropdown
              isOpen={isOpenCoach}
              toggle={() => setIsOpenCoach((prev) => !prev)}
              className="bg-white rounded w-100"
            >
              <DropdownToggle caret className="bg-white text-black rounded w-100 dropdown-menu-right">
                {(selectedCoach && selectedCoach.label) || "Tous les coachs"}
              </DropdownToggle>
              <DropdownMenu style={{ width: "100%", backgroundColor: "#1E2026" }}>
                {gyms.length &&
                  coachs.map((coach) => (
                    <div key={coach.value}>
                      <DropdownItem className="text-white bg-transparent" onClick={() => onSetCoachInput(coach)}>
                        {coach.label}
                      </DropdownItem>
                      <DropdownItem divider />
                    </div>
                  ))}
              </DropdownMenu>
            </Dropdown>
          </FormGroup>
        </div>
      )
    );
  };

  const renderCoordinates = () => {
    return (
      <div class=" mt-4">
        <div class="row">
          <FormGroup check className="col-3">
            <Label check className="text-white">
              <Input
                checked={gender === "male"}
                type="radio"
                name="radio1"
                onChange={(e) => {
                  setGender("male");
                }}
              />
              M
            </Label>
          </FormGroup>
          <FormGroup check className="col-3">
            <Label check className="text-white">
              <Input
                checked={gender === "femele"}
                type="radio"
                name="radio1"
                onChange={(e) => {
                  setGender("femele");
                }}
              />
              Mme
            </Label>
          </FormGroup>
          {validate.gender && <FormFeedback>{validate.gender}</FormFeedback>}
        </div>
        <div class="row">
          <FormGroup className="col-md-6 col-xs-6">
            <Input
              onChange={(e) => {
                setLastname(e.target.value);
              }}
              value={lastname}
              id="last_name"
              name="last_name"
              placeholder="Nom"
              invalid={validate.lastName}
            />
            {validate.lastName && <FormFeedback>{validate.lastName}</FormFeedback>}
          </FormGroup>
          <FormGroup className="col-md-6 col-xs-6">
            <Input
              valid={false}
              onChange={(e) => {
                setFirstname(e.target.value);
              }}
              value={firstname}
              id="first_name"
              name="first_name"
              placeholder="Prénom"
              invalid={validate.firstName}
            />
            {validate.firstName && <FormFeedback>{validate.firstName}</FormFeedback>}
          </FormGroup>
        </div>
        <div class="row">
          <FormGroup className="col-md-6 col-xs-6">
            <Input
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              value={phone}
              id="phone"
              name="phone"
              placeholder="Numéro de téléphone"
              type="number"
              invalid={validate.phone}
            />
            {validate.phone && <FormFeedback>{validate.phone}</FormFeedback>}
          </FormGroup>
          <FormGroup className="col-md-6 col-xs-6">
            <Input
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              id="email"
              name="email"
              placeholder="Adresse e-mail"
              type="email"
              invalid={validate.email}
            />
            {validate.email && <FormFeedback>{validate.email}</FormFeedback>}
          </FormGroup>
        </div>
      </div>
    );
  };

  const renderMoreInfos = () => {
    return (
      <div className="mt-4">
        <span className="text-white label">
          Dis-nous en plus afin que nos coachs puissent te guider au mieux vers tes objectifs !
        </span>
        <div class="row mt-2">
          <FormGroup className="col-4">
            <Input
              onChange={(e) => {
                setAge(e.target.value);
              }}
              value={age}
              id="age"
              name="age"
              placeholder="Age"
              type="number"
              invalid={validate.age}
            />
            {validate.age && <FormFeedback>{validate.age}</FormFeedback>}
          </FormGroup>
          <FormGroup className="col-4">
            <Input
              onChange={(e) => {
                setWeight(e.target.value);
              }}
              value={weight}
              id="weight"
              name="weight"
              placeholder="Poids"
              type="number"
              invalid={validate.weight}
            />
            {validate.weight && <FormFeedback>{validate.weight}</FormFeedback>}
          </FormGroup>
          <FormGroup className="col-4">
            <Input
              onChange={(e) => {
                setSize(e.target.value);
              }}
              value={size}
              id="size"
              name="size"
              placeholder="Taille"
              type="number"
              invalid={validate.size}
            />
            {validate.size && <FormFeedback>{validate.size}</FormFeedback>}
          </FormGroup>
        </div>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <Modal centered isOpen={isModalOpen} toggle={onCloseModal}>
        <ModalBody className="modalBody">
          <div>
            <p className="text-white text-center modalTitle mb-1 mt-1">
              Ta demande a bien été prise en compte et transmise aux coachs
            </p>
            <div className="d-flex justify-content-center px-4 py-3">
              <button type="button" className="modalBtn" onClick={onCloseModal}>
                Fermer
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <div class="joinUs" id="joinUs">
      <div class="container">
        <div className="col-md-6 mx-auto">
          <form onSubmit={{}} className="justify-content-center align-items-center col-md-12">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                {renderGymSelect()}
                {renderCoachSelect()}
                {renderCoordinates()}
                {renderMoreInfos()}

                <SportsExperiences sportExperience={sportExperience} setSportExperience={setSportExperience} />
                <Goals error={validate.goals} defaultGoals={defaultGoals} goals={goals} setGoals={setGoal} />
                <TrainingDays
                  days_preference={daysPreferences}
                  setDayPreference={setDayPreferencesInput}
                  time_preference={timesPreferences}
                  setTimePreference={setTimePreferencesInput}
                  error={validate.daysPreferences}
                />
                <div className="mt-5">
                  <FormGroup>
                    <Input
                      type="textarea"
                      onChange={(e) => {
                        setHealthProblemes(e.target.value);
                      }}
                      value={healthProbleme}
                      id="informations"
                      placeholder="Informations complémentaires"
                      style={{ height: 150 }}
                    />
                  </FormGroup>
                </div>
                {emailError && (
                  <span style={{ color: "#dc3545", fontSize: "0.875em", marginTop: "0.25rem" }}>{emailError}</span>
                )}
                {phoneError && (
                  <span style={{ color: "#dc3545", fontSize: "0.875em", marginTop: "0.25rem" }}>{phoneError}</span>
                )}
                <div className="row justify-content-center align-items-center">
                  <button
                    disabled={isChecking}
                    type="button"
                    onClick={onValidate}
                    className="mt-3 validate mb-3"
                    style={{ width: "95%" }}
                  >
                    Valider
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {renderModal()}
    </div>
  );
}
