import React from "react";
import PropTypes from "prop-types";
import { Navbar, Nav, NavItem } from "reactstrap";
import useGyms from "../../services/gymService";
export default function NavBarComponent({ removeToken, disableAction }) {
  const { getGym } = useGyms();
  const gym = getGym();
  return !gym ? null : (
    <div className="pt-4 justify-content-center align-items-center revolution-header">
      <Navbar color="" expand="md" className="align-baseline">
        <Nav className="me-auto" navbar>
          <NavItem className="align-baseline mt-2">
            <img src="../logo2.png" className="responsive-image mt-2" alt="Responsive" width="500px" />
          </NavItem>
        </Nav>
        {!disableAction && (
          <div>
            <p className="text-white gym mb-0">{gym.label.toUpperCase()}</p>
            <button
              className="btn btn-large bg-transparent float-end"
              onClick={removeToken}
              style={{ color: "#fff", padding: 0 }}
            >
              <div className="d-flex">
                <i className="bi bi-box-arrow-right"></i>
                <span className="text-white ms-2 logout">Déconnexion</span>
              </div>
            </button>
          </div>
        )}
      </Navbar>
    </div>
  );
}

NavBarComponent.propTypes = {
  removeToken: PropTypes.func.isRequired,
};
