import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  FormFeedback,
  Label,
} from "reactstrap";
import useProspects from "../../services/prospectService";
import Goals from "../../components/Goals/Goals";
import TrainingDays from "../../components/TrainingDays/TrainingDays";
import CommercialsActivities from "../../components/CommercialsActivities/CommercialsActivities";

export default function DashboardScreen() {
  const [isOpen, setOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalValidate, setModalValidate] = useState(false);
  const setCheck = () => setOpen(!isOpen);

  const {
    fetchCommercials,
    commercials,
    fetchGoals,
    defaultGoals,
    onSetCommercialInput,
    selectedCommercial,
    setFirstname,
    firstname,
    setLastname,
    lastname,
    setPhone,
    phone,
    setEmail,
    email,
    addDefaultGoals,
    setGoalInput,
    goalInput,
    setGoal,
    goals,
    setDayPreferencesInput,
    daysPreferences,
    setTimePreferencesInput,
    timesPreferences,
    setHealthProblemes,
    healthProbleme,
    sendProspect,
    setReloadData,
    reloadData,
    onValidate,
    validate,
    gender,
    setGender,
    age,
    setAge,
  } = useProspects();

  useEffect(() => {
    fetchCommercials();
    fetchGoals();
  }, [fetchCommercials, fetchGoals]);

  const handleSubmit = async () => {
    const isValid = await onValidate();

    if (isValid) setModalOpen(true);
  };

  const onValidateSend = async () => {
    sendProspect();
    setModalValidate(true);
    setReloadData(true);
    setTimeout(() => setReloadData(false), 500);
    setTimeout(() => onCloseModal(), 2000);
  };

  const onCloseModal = async () => {
    setModalOpen(false);
    setModalValidate(false);
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit} className="justify-content-center align-items-center col-md-12">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 px-5">
            <FormGroup>
              <Dropdown isOpen={isOpen} toggle={setCheck} className="bg-white rounded w-100">
                <DropdownToggle caret className="bg-white text-black rounded w-100 dropdown-menu-right">
                  {(selectedCommercial && selectedCommercial.label) || "Commercial"}
                </DropdownToggle>
                <DropdownMenu style={{ width: "100%", backgroundColor: "#1E2026" }}>
                  {commercials.length &&
                    commercials.map((commercial) => (
                      <div key={commercial.value}>
                        <DropdownItem
                          className="text-white bg-transparent"
                          onClick={() => onSetCommercialInput(commercial)}
                        >
                          {commercial.label}
                        </DropdownItem>
                        <DropdownItem divider />
                      </div>
                    ))}
                </DropdownMenu>
              </Dropdown>
              {validate.commercial && <span className="required">{validate.commercial}</span>}
            </FormGroup>
            <div className="mt-3 mb-2">
              <span className="text-white label">Prospect :</span>
            </div>
            <div class="row">
              <FormGroup check className="col-3">
                <Label check className="text-white">
                  <Input
                    checked={gender === "male"}
                    type="radio"
                    name="radio1"
                    onChange={(e) => {
                      setGender("male");
                    }}
                  />
                  M
                </Label>
              </FormGroup>
              <FormGroup check className="col-3">
                <Label check className="text-white">
                  <Input
                    checked={gender === "female"}
                    type="radio"
                    name="radio1"
                    onChange={(e) => {
                      setGender("female");
                    }}
                  />
                  Mme
                </Label>
              </FormGroup>
            </div>
            <FormGroup>
              <Input
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
                value={lastname}
                id="last_name"
                name="last_name"
                placeholder="Nom"
                invalid={validate.lastName}
              />
              {validate.lastName && <FormFeedback>{validate.lastName}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Input
                valid={false}
                onChange={(e) => {
                  setFirstname(e.target.value);
                }}
                value={firstname}
                id="first_name"
                name="first_name"
                placeholder="Prénom"
                invalid={validate.firstName}
              />
              {validate.firstName && <FormFeedback>{validate.firstName}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Input
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                value={phone}
                id="phone"
                name="phone"
                placeholder="Numéro de téléphone"
                type="number"
                invalid={validate.phone}
              />
              {validate.phone && <FormFeedback>{validate.phone}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
                id="email"
                name="email"
                placeholder="Adresse e-mail"
                type="email"
                invalid={validate.email}
              />
              {validate.email && <FormFeedback>{validate.email}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Input
                onChange={(e) => {
                  setAge(e.target.value);
                }}
                value={age}
                id="age"
                name="age"
                placeholder="Âge"
                type="number"
                invalid={validate.age}
              />
              {validate.age && <FormFeedback>{validate.age}</FormFeedback>}
            </FormGroup>
            <Goals
              error={validate.goals}
              defaultGoals={defaultGoals}
              goals={goals}
              setGoals={setGoal}
              addDefaultGoals={addDefaultGoals}
              setGoalInput={setGoalInput}
              goalInput={goalInput}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-5 px-5">
            <TrainingDays
              days_preference={daysPreferences}
              setDayPreference={setDayPreferencesInput}
              time_preference={timesPreferences}
              setTimePreference={setTimePreferencesInput}
            />
            <div className="mt-5">
              <FormGroup>
                <Input
                  type="textarea"
                  onChange={(e) => {
                    setHealthProblemes(e.target.value);
                  }}
                  value={healthProbleme}
                  id="informations"
                  placeholder="Informations complémentaires"
                  style={{ height: 150 }}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <button type="button" onClick={handleSubmit} className="mt-3 validate" style={{ width: "40%" }}>
              Valider
            </button>
          </div>
        </div>
      </form>
    );
  };

  const renderModal = () => {
    return (
      <Modal centered isOpen={isModalOpen} toggle={onCloseModal}>
        <ModalBody className="modalBody">
          {!isModalValidate ? (
            <div>
              <p className="text-white text-center modalTitle mb-1 mt-1">Confirmer l'envoi du prospect ?</p>
              <div className="d-flex justify-content-between px-4 py-3">
                <button type="button" className="modalBtn" onClick={onValidateSend}>
                  Confirmer
                </button>
                <button type="button" className="modalBtn" onClick={onCloseModal}>
                  Annuler
                </button>
              </div>
            </div>
          ) : (
            <p className="text-white text-center modalTitle mb-1 mt-1">Le prospect a été envoyé avec succès</p>
          )}
        </ModalBody>
      </Modal>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row row-eq-height">
        <div className="col-md-8 wrapper px-4 py-4 row-eq-height">
          <p className="title text-center pb-3">INSCRIPTION PROSPECT</p>
          {renderForm()}
        </div>
        <div className="col-md-4 col-sm-12 col-lg-4 ps-4 row-eq-height">
          <CommercialsActivities reloadData={reloadData} />
        </div>
      </div>
      {renderModal()}
    </div>
  );
}
