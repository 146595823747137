import React from "react";
import "./scss/revolution.scss";
import "./App.css";
import CommercialAdminPage from "./screens/CommercialAdminPage/CommercialAdminPage";
// import HomePage from "./screens/HomePage/HomePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FooterComponent from "./components/FooterComponent/FooterComponent";
import ForgetPasswordPage from "./screens/ForgetPasswordPage/ForgetPasswordPage";
import RegisterPage from "./screens/RegisterPage/RegisterPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<HomePage />} /> */}
        <Route path="/" element={<CommercialAdminPage />} />
        <Route path="/admin/commercials" element={<CommercialAdminPage />} />
        <Route path="/forgotPassword/:token" element={<ForgetPasswordPage />} />
        <Route path="/registration" element={<RegisterPage />} />
      </Routes>
      <FooterComponent />
    </BrowserRouter>
  );
}

export default App;
