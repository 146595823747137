import { useEffect } from "react";
import moment from "moment";
import useCommercialsActivities from "../../services/commercialsActivitiesService";
import "./CommercialsActivites.css";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export const options = {
  plugins: {
    legend: false,
  },
  scales: {
    y: {
      ticks: {
        color: "#fff",
        // fontSize: 18,
        font: {
          size: 12,
        },
        beginAtZero: true,
      },
    },
    x: {
      ticks: {
        color: "#FFF",
        //fontSize: 14,
        font: {
          size: 12,
        },
        beginAtZero: true,
      },
    },
  },
};

export default function CommercialsActivities({ reloadData }) {
  const {
    currentMonth,
    setMonth,
    fetchGymInfos,
    gymInfos,
    totalCom,
    totalProspect,
    totalAthleteSent,
    fetchGymProspecting,
    prospectingInfos,
  } = useCommercialsActivities();

  useEffect(() => {
    fetchGymInfos(gymInfos);
    fetchGymProspecting(prospectingInfos);
  }, [reloadData]);

  const onChangeMonth = (isNext) => {
    const month = currentMonth;
    !isNext ? month.subtract(1, "months") : month.add(1, "months");
    setMonth(moment(month));
    fetchGymInfos();
    fetchGymProspecting();
  };

  const data = {
    font: {
      weight: "bold",
      size: 16,
    },
    labels: [],
    datasets: [
      {
        color: "blue",

        label: "Nombre de prospects",
        data: [],
        // you can set indiviual colors for each bar
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
        font: {
          weight: "bold",
          size: 16,
        },
      },
    ],
  };
  prospectingInfos.map((info) => {
    data.labels.push(info.gym_name);
    data.datasets[0].data.push(info.number_of_athletes);
    data.datasets[0].backgroundColor.push("#2CDEE4");
  });

  return (
    <div>
      <div className="d-flex justify-content-md-center align-items-center">
        <button
          className="btn text-white col-md-2 col-sm-2"
          type="button"
          onClick={() => {
            onChangeMonth();
          }}
        >
          <i className="bi bi-chevron-left"></i>
        </button>
        <p className="text-white mb-0 fw-bold text-italic">{currentMonth.format("MMMM YYYY").toUpperCase()}</p>
        <button
          className="btn text-white col-md-2"
          type="button"
          onClick={() => {
            onChangeMonth(true);
          }}
        >
          <i className="bi bi-chevron-right"></i>
        </button>
      </div>
      <div className="tableContainer text-white pt-3 text-bold">
        <span className="text-white fst-italic title-section ps-3 commercial-italic ">ACTIVITÉ COMMERCIALE</span>
        <div className="row px-3 pt-2 mb-0 text-center">
          <div className="col-md col-sm col-lg">
            <span>Commercial</span>
          </div>
          <div className="col-md col-sm col-lg">
            <span>Prospects envoyés</span>
          </div>
          <div className="col-md col-sm col-lg">
            <span>Prospects réels</span>
          </div>
          <div className="col-md col-sm col-lg">
            <span>Prime (brute)</span>
          </div>
        </div>
        <div className="tableContent py-3">
          {gymInfos.length &&
            gymInfos.map((commercial) => {
              return (
                <div
                  className="text-center text-black bg-white m-2 px-2  py-1 mb-0 d-flex justify-content-md-center align-items-center"
                  key={commercial.full_name}
                  style={{ borderRadius: "5px" }}
                >
                  <div className="col-md col-sm col-lg">
                    <span>{commercial.full_name}</span>
                  </div>
                  <div className="col-md col-sm col-lg">
                    <span>{commercial.number_of_athlete}</span>
                  </div>
                  <div className="col-md col-sm col-lg">
                    <span>{commercial.totalAthleteSent}</span>
                  </div>
                  <div className="col-md col-sm col-lg">
                    <span>{commercial.totalCom}</span>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="row textColored p-2 m-2 pt-1 mt-0 text-center">
          <div className="col-md col-sm col-lg">
            <span style={{ fontSize: 18 }}>TOTAL</span>
          </div>
          <div className="col-md col-sm col-lg">
            <span style={{ fontSize: 18 }}>{totalProspect}</span>
          </div>
          <div className="col-md col-sm col-lg">
            <span style={{ fontSize: 18 }}>{totalAthleteSent}</span>
          </div>
          <div className="col-md col-sm col-lg">
            <span style={{ fontSize: 18 }}>{totalCom}</span>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="p-3 wrapper">
          <p className="text-white fst-italic title-section">PROSPECTION DES PARTENAIRES</p>
          <Bar options={options} data={data} />
        </div>
      </div>
    </div>
  );
}
