import React from "react";
import DashboardScreen from "../DashboardScreen/DashboardScreen";
import LoginScreen from "../LoginScreen/LoginScreen";
import useToken from "../../services/tokenService";
import NavBarComponent from "../../components/HeaderComponent/HeaderComponent";
import useGyms from "../../services/gymService";
export default function CommercialAdminPage() {
  const { token, setToken, removeToken } = useToken();
  const { removeGym, saveGym } = useGyms();

  const onLogin = (data) => {
    saveGym({ ...data.selectedGym });
    setToken({ token: data.token });
  };
  const onLogout = () => {
    removeGym();
    removeToken();
  };
  return !token ? (
    <div className="container-fluid background">
      <div className="d-flex justify-content-md-center align-items-center vh-100">
        <LoginScreen setToken={onLogin} />
      </div>
    </div>
  ) : (
    <div className="container-fluid bg-black">
      <NavBarComponent removeToken={onLogout} />
      <div className="d-flex mt-3">
        <DashboardScreen />
      </div>
    </div>
  );
}
