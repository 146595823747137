import React from "react";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import useController from "./ForgetPasswordPage.controller";

export default function ForgetPasswordPage() {
  const {
    isLoading,
    password,
    passwordConfirmation,
    setPassword,
    setPasswordConfirmation,
    handleSubmit,
    validate,
    success,
    isTokenValid,
  } = useController();

  return isLoading ? null : (
    <div className="container-fluid background">
      <div className="d-flex justify-content-md-center align-items-center vh-100">
        <section class="forgetPassword" id="forgetPassword">
          {!isTokenValid ? (
            <div>
              <h2 className="text-white">Ta demande de réinitialisation de mot de passe a expiré</h2>
            </div>
          ) : (
            <div className="justify-content-center mx-5">
              <img src="../logo2.png" className="brand_logo img-fluid" alt="responsive" width="600px" />
              <div className="pt-5 justify-content-center">
                <div className="col-md-8 offset-md-2 col-sm-8 offset-sm-2">
                  <p className="title text-center pb-3">SAISIS TON NOUVEAU MOT DE PASSE</p>
                  <form onSubmit={handleSubmit}>
                    <FormGroup className="mt-3">
                      <Label className="text-white label">Nouveau mot de passe :</Label>
                      <Input
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        className="w-100"
                        name="password"
                        placeholder="Mot de passe"
                        type="password"
                        invalid={validate.password}
                      />
                      {validate.password && <FormFeedback>{validate.password}</FormFeedback>}
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <Label className="text-white label">Confirme ton mot de passe :</Label>
                      <Input
                        value={passwordConfirmation}
                        onChange={(e) => {
                          setPasswordConfirmation(e.target.value);
                        }}
                        className="w-100"
                        name="password"
                        placeholder="Confirmation du mot de passe"
                        type="password"
                        invalid={validate.passwordConfirmation}
                      />
                      {validate.passwordConfirmation && <FormFeedback>{validate.passwordConfirmation}</FormFeedback>}
                    </FormGroup>
                    {success && <p className="text-white text-center">{success}</p>}
                    <div className="text-center">
                      <button type="button" onClick={handleSubmit} className="loginButton mt-3">
                        Valider
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
    </div>
  );
}
